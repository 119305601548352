var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6.137.0_f10626787cb9602317b1bb9ccee7f21728552570"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

import Config from "@spatialsys/web/config"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: Config.DEPLOYMENT_ENV === "production" ? 0.02 : 1.0,
  environment: Config.SENTRY_ENVIRONMENT,
  beforeSend(event) {
    const transactionTag = event.tags?.transaction
    if (
      typeof transactionTag === "string" &&
      (transactionTag.startsWith("getInitialProps") || transactionTag === "middleware")
    ) {
      return null // Prevents sending these event to Sentry
    }
    return event
  },
  integrations: [new Sentry.BrowserProfilingIntegration()],
  // This is relative to tracesSampleRate, meaning, 0.2 * 0.06 = 1.2% of transactions will be sampled
  profilesSampleRate: Config.DEPLOYMENT_ENV === "production" ? 0.1 : 1.0,
})
Sentry.addTracingExtensions()
